import request from '@/utils/request'

// 查询所有学校
export function getAllSchool() {
  return request({
    url: '/common/com/school/getAllSchool',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

