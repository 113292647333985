<!-- 登录 -->
<template>
  <div>
    <!-- 登录 -->
    <div v-if="mainShow" id="login" ref="login" style="height:100%">
      <!-- 顶部 -->
      <div class="top">
        <!-- 标题图片 -->
        <div class="top-nav">
          <!-- <img :src="imageList[0]" alt="" class="imgClass"> -->
          <span class="btn-text" @click="changeLanguage">{{ language }} ></span>
          <span class="btn-text-right" @click="toPayQueryLogin">{{ $t('login.payQuery') }} ></span>
        </div>
      </div>
      <!-- 校园统一身份认证登录 -->
      <div class="school-log">
        <div class="school-img">
          <div>
            <p class="text-first">{{ $t('login.title1') }}</p>
            <p class="text-second">{{ $t('login.title2') }}</p>
          </div>
          <img :src="imageList[1]" alt="">
        </div>
      </div>
      <!-- 表单 -->
      <div class="form">
        <van-form validate-first input-align="left" label-width="0px">
          <van-cell-group>
            <van-field
              clickable
              readonly
              :value="loginForm.schoolName"
              label=" "
              :placeholder="$t('login.school')"
              label-width="0px"
              @click="schoolShow = true"
            />
            <van-popup v-model="schoolShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="schoolCodeList"
                @confirm="onConfirmSchool"
                @cancel="schoolShow = false"
              />
            </van-popup>
          </van-cell-group>
          <van-cell-group>
            <van-field
              v-model="loginForm.username"
              label=" "
              :placeholder="$t('login.idserial')"
              label-width="0px"
              autocapitalize="off"
              autocorrect="off"
              clearable
            />
          </van-cell-group>
          <van-cell-group>
            <van-field
              v-model="loginForm.verifycode"
              maxlength="4"
              label=" "
              :placeholder="$t('login.v-code')"
              label-width="0px"
              type="digit"
              clearable
            >
              <template #button>
                <div class="login-code2" @click="refreshCode">
                  <s-identify :identify-code="identifyCode" />
                </div>
              </template>
            </van-field>
          </van-cell-group>
          <!-- 按钮组 -->
          <div class="btn-group">
            <van-button
              block
              type="info"
              native-type="submit"
              class="btn-class submit"
              @click="goToLogin"
            >
              {{ $t('login.quickly-pay') }}
            </van-button>
            <!-- <van-button block type="info" plain class="btn-class">
              <span class="btn-text">{{ $t('login.status') }}</span>
            </van-button> -->
            <van-button block type="info" plain class="btn-class" @click="changeLanguage">
              <span class="btn-text">{{ language }}</span>
            </van-button>
            <!-- <p class="text-center engLish" @click="toSign()">{{ $t('login.fee') }}&gt;</p> -->
          </div>
        </van-form>
      </div>
      <!-- 底部 -->
      <!-- <div class="bottom">
        <div class="border-dash-middle">
          <div class="login-other">
            <p class="other-method">{{ $t('login.change') }}</p>
          </div>
        </div>
        <div class="link">
          <div class="link-btn-group">
            <div class="flex">
              <img :src="imageList[2]" alt="" class="link-img">
            </div>
            <div class="flex">
              <img :src="imageList[3]" alt="" class="link-img">
            </div>
            <div class="flex">
              <img :src="imageList[4]" alt="" class="link-img">
            </div>
          </div>
          <div class="psw-btn" @click="pageToAccount">
            <van-icon name="manager" />
            {{ $t('main.accountLogin') }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import Config from '@/settings'
import SIdentify from '@/components/Identify'
import { getAllSchool } from '@/api/school'
// import SIdentify2 from '@/components/Identify2'
import { Toast } from 'vant'
import Vue from 'vue'
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
export default {
  name: 'Login',
  components: {
    SIdentify
  },
  data() {
    return {
      // selectOptionWidth: '',
      schoolCodeList: [], // 学校列表
      schoolShow: false,
      language: '',
      mainShow: true,
      signUpShow: false,
      schoolCode: '',
      registerShow: false,
      imageList: [
        require('../../../assets/newImages/login5.png'),
        require('../../../assets/newImages/login2.png'),
        require('../../../assets/newImages/login4.png'),
        require('../../../assets/newImages/login3.png'),
        require('../../../assets/newImages/login1.png')
      ],
      loginForm: {
        username: '',
        password: '',
        verifycode: '',
        loginStatus: false,
        accountType: 'BASE',
        schoolName: '',
        schoolCode: ''
      },
      // 验证码随机数集合
      identifyCodes: '1234567890',
      // 定义验证码
      identifyCode: ''
    }
  },
  computed: {
    passwordIcon: function() {
      return this.passwordType === 'password' ? 'closed-eye' : 'eye'
    }
  },
  beforeMount() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
    console.log(11)
  },
  mounted() {
    this.schoolCode = window.localStorage.getItem('schoolcode')
    this.loginForm.schoolCode = window.localStorage.getItem('schoolcode')
    // const jsonData = this.$route.query.jsonData
    // window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
    // window.localStorage.setItem('openId', JSON.parse(jsonData).openId)
    // 區分來源
    window.localStorage.setItem('enterSource', this.$route.query.enterSource)
    console.log(this.$route.query.enterSource)
    // 验证码初始化
    this.identifyCode = ''
    // 随机获取4位长度的验证码
    this.makeCode(this.identifyCodes, 4)
    this.setLanguage()
    this.getAllSchool()
  },
  methods: {
    toPayQueryLogin() {
      this.$router.push({ path: '/' })
    },
    // 获取字典 订单状态
    getAllSchool() {
      getAllSchool().then(response => {
        const tempList = response.data
        // 给list新增text属性 值为value   只有vant这个框架只接收text
        for (let i = 0; i < response.data.length; i++) {
          this.$set(tempList[i], 'text', tempList[i].schoolName)
        }
        this.schoolCodeList = tempList

        // this.schoolCodeList = response.data
      })
    },
    // 确认学校
    onConfirmSchool(val) {
      this.loginForm.schoolName = val.schoolName
      this.loginForm.schoolCode = val.schoolCode
      this.schoolShow = false
    },
    pageToAccount() {
      this.$router.push({ path: '/account' })
    },
    // 初始化语言
    setLanguage() {
      if (window.localStorage.getItem('language') === 'cn') {
        this.language = 'English'
      } else if (window.localStorage.getItem('language') === 'en') {
        this.language = '中文'
      } else {
        this.language = 'English'
      }
    },
    // 更改语言
    changeLanguage() {
      if (window.localStorage.getItem('language') === 'cn') {
        window.localStorage.setItem('language', 'en')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = '中文'
        this.$Local('en')
      } else if (window.localStorage.getItem('language') === 'en') {
        window.localStorage.setItem('language', 'cn')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = 'English'
        this.$Local('cn')
      }
    },
    changeView() {
      this.$router.push('/signUp')
      // if (value === 'registerShow') {
      //   this.mainShow = false
      //   this.signUpShow = false
      //   this.registerShow = true
      // } else if (value === 'signUpShow') {
      //   this.mainShow = false
      //   this.registerShow = false
      //   this.signUpShow = true
      // }
    },
    toSign() {
      this.$router.push({ path: 'signUp' })
    },
    toRegister() {
      this.$router.push({ path: 'register' })
    },
    // 验证码自定义验证规则
    validateVerifycode() {
      if (this.loginForm.verifycode === '') {
        Toast.fail(this.$t('msg.writeCode'))
        return false

        // 请输入验证码
      } else if (this.loginForm.verifycode !== this.identifyCode) {
        Toast.fail(this.$t('msg.wrongCode'))
        // 验证码不正确
        return false
      } else {
        return true
      }
    },
    goToLogin() {
      const verifycodePass = this.validateVerifycode()
      if (verifycodePass === true) {
        if (
          this.loginForm.username === null ||
        this.loginForm.username === ''
        ) {
          Toast.fail(this.$t('msg.login'))
          return false
        } else if (this.loginForm.schoolCode === null || this.loginForm.schoolCode === '') {
          Toast.fail(this.$t('msg.chooseSchool'))
          return false
        } else {
          var param = JSON.parse(JSON.stringify(this.loginForm))
          // console.log(param)
          param.loginStatus = Boolean(param.loginStatus)
          if (param.loginStatus) {
            Cookies.set('username', param.username, { expires: Config.passCookieExpires })
            Cookies.set('password', param.password, { expires: Config.passCookieExpires })
            Cookies.set('loginStatus', param.loginStatus, { expires: Config.passCookieExpires })
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('loginStatus')
          }
          // window.localStorage.getItem('schoolcode') = this.loginForm.schoolCode
          window.localStorage.setItem('schoolcode', this.loginForm.schoolCode)
          this.$store.dispatch('user/quicklyLogin', param).then(() => {
            Toast.success(this.$t('msg.loginSuccess'))
            window.localStorage.setItem('accountNum', this.loginForm.username)
            // 获取url地址中的schoolCode
            window.localStorage.setItem('schoolCode', this.loginForm.schoolCode)
            this.$router.push({ path: '/paymentOnBehalf', query: { 'accountNum': this.loginForm.username, 'schoolCode': this.loginForm.schoolCode }})
            this.$store.dispatch('user/getInfo')
          })
        }
      }
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.van-button{
  font-size: 16px;
}
.login-code2{
  height: 24px;
  position: relative;
  top:-4px;
}
#login{
  padding:0 30px;
}
.top{
  margin-top:38px;
  .top-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:12px;
    color: rgba(116,116,116,1);
  }
  .imgClass{
    display: block;
    height: 28px;
  }
  .session{
    margin-top:10px ;
    .left-text{
      margin-top:45px;
      margin-left:8px;
      .text-first{
        font-size: 24px;
        font-weight: bold;
       }
      .text-second{
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }
}
.school-log{
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 10px 0px rgba(47,159,242,0.15);
  border-radius:4px;
  margin-top: 23px;
  padding-bottom: 52px;
  .school-img{
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size:17px;
    color:rgba(46,46,46,1);
    padding-top: 33px;
    .text-first{
      margin-bottom: 10px;
    }
    img{
      height: 100px;
    }
  }
  .btn-class{
    width: 90%;
    margin: 0 auto 12px;
    border-radius: 4px;
  }
  .margin-top-35{
    margin-top: 35px;
  }
}
.form{
  margin-top:30px;
}
.van-cell-group{
  border-bottom:1px solid #D0D0D0
}
.btn-group{
  margin-top:40px;
  .btn-class{
    margin: 10px 0;
  }
  .btn-text{
    color: #2F9FF2;
  }
}
.btn-text-right{
  color: #2F9FF2;
}
.engLish{
  margin-top:20px;
  font-weight:bold !important;
  cursor: pointer;
  height:16px;
  font-size:16px;
  font-family:STHeitiSC-Light,STHeitiSC;
  font-weight:300;
  color:#2F9FF2;
  line-height:16px;
}
.bottom{
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
}
.border-dash-middle{
  border-bottom:1px dashed #D0D0D0;
  position: relative;
}
.login-other{
  text-align: center;
  width:120px;
  padding: 0 10px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  font-size:14px;
  font-family:STHeitiSC-Medium,STHeitiSC;
  font-weight:500;
  color:rgba(208,208,208,1);
  line-height:14px;
  background: #fff;
}
.link{
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
  .link-btn-group{
    display: flex;
    .flex{
      .link-img{
        cursor: pointer;
        display: block;
        margin: 0 auto;
        height: 40px;
        width: 40px;
        margin-right: 14px;
      }
    }
  }
  .psw-btn{
    width: 130px;
    height: 38px;
    font-size:14px;
    color: rgba(47,159,242,1);
    border-radius:20px;
    border:1px solid rgba(47,159,242,0.6);
    display: flex;
    align-items: center;
    justify-content:center;
  }
}
.right-img{
  width: 150px;
  height: 121px;
  img{
    width: 100%;
  }
}
.van-hairline--top-bottom {
    position: relative;
    top: -2px
}
.van-cell-group {
    border-bottom: 0.02667rem solid #D0D0D0;
    // border-bottom: none;
}
.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border-width: 0;
}
</style>
